<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: pondTab.vue
Description: This file contains UI components of pond tab from settings section. It is the parent comoponent of ponds table in settings
-->
<template>
  <el-row
    class="pond-table"
    element-loading-background="white"
    v-loading="loading"
  >
    <el-row v-if="!loading && Ponds.length > 0">
      <layout-toolbar class="action-toolbar">
        <div style="display: flex; margin-right: auto">
          <el-radio-group
            :value="selectedStatus"
            size="small"
            @input="handleStatusChange"
          >
            <el-radio-button label="ACTIVE" class="is-plain">
              {{ $t("Comn_active") }}
            </el-radio-button>
            <el-radio-button label="INACTIVE" class="is-plain">
              {{ $t("Comn_inactive") }}
            </el-radio-button>
            <el-radio-button label="DELETED" class="is-plain">
              {{ $t("Comn_deleted") }}
            </el-radio-button>
            <el-radio-button label="ALL" class="is-plain">
              {{ $t("Comn_all") }}
            </el-radio-button>
          </el-radio-group>
        </div>
        <er-input
          v-show="!loading"
          inputType="search"
          v-model="pondSearch"
          size="mini"
          :placeholder="$t('Map_pond_name_search')"
          suffix-icon="el-icon-search"
          @change="handelSearchChange"
        ></er-input>
        <er-button
          v-show="!loading"
          btnType="circle_add"
          :showLabel="true"
          :showIcon="true"
          size="mini"
          :disabled="isReadOnly"
          @click="handleAddPondClick"
        >
          <slot>
            <p style="text-transform: uppercase">{{ $t("Comn_add_pond") }}</p>
          </slot>
        </er-button>
        <er-column-selector
          :key-to-store-defaults="`${getUserId}-TABLE-PREF-POND-INFO`"
          :columns="tableColumns"
          :srcImg="'COLUMN_ICON'"
          :type="'columns'"
           @column-save="handelSaveColumns"
          :title="ftm__capitalize($t('columns'))"
          @change-selection="handleChangeInColumnSelection"
        ></er-column-selector>
        <er-dropdown
          placement="bottom"
          @item-click="handleTableDataDownload"
          trigger="click"
          class="highlight"
          :listOfItems="['Comn_download_as_excel', 'Comn_download_as_pdf']"
        >
          <template class="el-dropdown-link download_button" slot="suffix">
            <span class="material-icons-outlined action-icon">
              file_download
            </span>
          </template>
          <template v-slot:item-title="{ item }">{{ $t(item.value) }}</template>
        </er-dropdown>
      </layout-toolbar>
      <el-row>
        <er-data-tables
          ref="pondsettings"
          uniqueId="pond-settings__table"
          :key="$i18n.locale"
          :tableData="dataValues"
          :actionColumn="false"
          type="white-header-table"
          element-loading-background="white"
          :columns="tableColumns"
          :selectedColumnHeaders="selectedColumns"
          :selectedColumnHeadersSortable="true"
          :total="total"
          v-show="!loading"
          :filters="filters"
          :elTableProps="tableProps"
          :sortMethodForColumns="sortMethods()"
          :page-size="$constants.table['page-size']"
          :pagination-props="$constants.table['pagination-props']"
          @cell-click="showPondDetails"
          :searchOnTableData="pondSearch"
        >
          <template v-slot:title="{ row }">
            <er-highlight-search-component
              :highlightStrings="row.data.highlightStrings"
              :individualRowData="row.data"
              keyName="title"
              :withoutHighlightStrings="trim_nameSpace(row.data.title)"
            />
          </template>
          <template v-slot:size="{ row }">
            <!-- {{ row.data.size }} -->
            <!-- <p
              v-upm__units.digitPrecision="
                getPondSizeAndUnits(row.data.size, row.data.units)
              "
            ></p> -->
            <p>{{getPondSizeAndUnits(row.data.size, row.data.units)}}</p>
          </template>

          <template v-slot:ui_cultivation_date="{ row }">
            <template v-if="row.data.status === 'ACTIVE'">
              {{
                row.data.ui_cultivation_date &&
                  ftm__formatEmpty(
                    formatCultivationDate(row.data.ui_cultivation_date)
                  )
              }}
            </template>

            <template v-else>00-000 00</template>
          </template>

          <template v-slot:post_larva_stocked="{ row }">
            <p
              v-upm__nounits.digitPrecision.formatNumber="
                getPLStockedAndUnits(row.data.post_larva_stocked)
              "
            ></p>
          </template>

          <template v-slot:feed_template_title="{ row }">
            {{ ftm__formatEmpty(row.data.feed_template_title) }}
          </template>
          <template v-slot:test_status="{ row }">
            <el-switch
              :value="row.data.is_test"
              @input="handleChangeStatus($event, 'TESTING', row.data)"
            ></el-switch>
          </template>

          <template v-slot:status="{ row }">
            <div style="display: flex" class="status-container">
              <div
                v-if="row.data.status === 'ACTIVE'"
                class="table-row-btn-container"
              >
                <div class="edit-btn-container">
                  <er-button
                    type="text"
                    size="mini"
                    btnType="edit"
                    :showLabel="true"
                    :showIcon="true"
                    :disabled="isReadOnly"
                    @click="handleChangeStatus($event, 'EDIT', row.data)"
                  ></er-button>
                </div>
                <el-divider
                  direction="vertical"
                  style="height: 100%"
                ></el-divider>

                <er-button
                  type="text"
                  size="mini"
                  btnType="delete"
                  :showLabel="true"
                  :showIcon="true"
                  :disabled="isReadOnly"
                  @click="handleChangeStatus($event, 'RESET', row.data)"
                ></er-button>
              </div>
              <div
                v-else-if="row.data.status === 'DELETED'"
                class="table-row-btn-container"
              >
                <!-- <div class="edit-btn-container">
                  <er-button
                    type="text"
                    size="mini"
                    btnType="edit"
                    :showLabel="true"
                    :showIcon="true"
                    :disabled="isReadOnly"
                    @click="handleChangeStatus($event, 'EDIT', row.data)"
                  ></er-button>
                </div>
                <el-divider
                  direction="vertical"
                  style="height: 100%"
                ></el-divider> -->

                <er-button
                  type="text"
                  size="mini"
                  btnType="enable"
                  :showLabel="true"
                  :showIcon="true"
                  :disabled="isReadOnly"
                  @click="handleChangeStatus($event, 'ENABLE', row.data)"
                ></er-button>
              </div>
              <div v-else class="table-row-btn-container">
                <div class="edit-btn-container">
                  <er-button
                    type="text"
                    size="mini"
                    btnType="activate"
                    :showLabel="true"
                    :showIcon="true"
                    :disabled="isReadOnly"
                    @click="handleChangeStatus($event, 'ACTIVE', row.data)"
                  ></er-button>
                </div>
                <el-divider
                  direction="vertical"
                  style="height: 100%"
                ></el-divider>
                <er-button
                  type="text"
                  size="mini"
                  btnType="delete"
                  :showLabel="true"
                  :showIcon="true"
                  :disabled="isReadOnly"
                  @click="handleChangeStatus($event, 'RESET', row.data)"
                ></er-button>
              </div>
            </div>
          </template>
        </er-data-tables>
      </el-row>
      <component
        :is="`cultureActionDialog`"
        :showDialog="dialogVisible"
        :pond="selectedPond"
        :actionToPerform="actionToPerform"
        @close_dialog="closeDialog"
      ></component>
    </el-row>
    <Loader v-else-if="loading"></Loader>
    <er-no-ponds :tabData="tabData" :disabled="isReadOnly" v-else />
    <er-dialog
    :title="getDialogTitle"
    :visible="isDeleteConfirmationPopup"
    :modal-append-to-body="false"
    width="420px"
    :close-on-click-modal="false"
    @open="resetForm"
    @close="handleDialogClose"
    class="delete-pond-confirmation-dialog"
  >
    <el-form
      :ref="formName"
      :model="form"
      size="mini"
    >
      <el-form-item
        prop="first_name"
        class="name-element"
        :label="DeleteConfirmationFormLabel[0].label"
      >
        <el-input
          v-model="form.deleteConfirmationString"
        >
        </el-input>
      </el-form-item>
    </el-form>

    <template slot="footer">
      <el-row type="flex" justify="end" align="middle">
        <er-button
          size="mini"
          btnType="deleteForever"
          :disabled="shouldDeleteConfirmationButtonEnable || isReadOnly"
          :showLabel="true"
          :showIcon="true"
          @click="submitForm"
        >
        </er-button>
        <er-button
          size="mini"
          btnType="cancel"
          :showLabel="true"
          @click="handleDialogClose"
        >
        </er-button>
      </el-row>
    </template>
  </er-dialog>
  </el-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import errorHandlerMixin from "@/mixins/errorHandlerMixin.js";
import cultureActionDialog from "./cultureActionDialog";
import Pond from "@/model/pond";
import filtersMixin from "@/mixins/filtersMixin.js";
import datesHandlerMixin from "@/mixins/datesHandlerMixin.js";
import notificationMixin from "@/mixins/notificationMixin";
import userPreferenceMixin from "@/mixins/userPreferenceMixin";
import redirectsMixin from "@/mixins/redirectsMixin";
import Loader from "@/components/base/Loader";
// import { jsPDF as JSPDF } from "jspdf";
// import XLSX from "xlsx";
// import autoTable from "jspdf-autotable";
import exportFileDownload from "@/mixins/exportFileDownload";
// import { DATE_OBJ_DATE_FORMAT_WITH_YEAR } from "@/utils/commonUtils";
export default {
  mixins: [
    errorHandlerMixin,
    exportFileDownload,
    filtersMixin,
    notificationMixin,
    userPreferenceMixin,
    datesHandlerMixin,
    redirectsMixin
  ],
  components: {
    cultureActionDialog,
    Loader
  },
  props: {
    tabData: {
      default: {}
    }
  },
  data: function() {
    return {
      data: [],
      total: 0,
      value1: true,
      loading: false,
      selectedStatus: "ACTIVE",
      selectedPond: new Pond(),
      actionToPerform: "",
      dialogVisible: false,
      currentFilters: new Map(),
      multiSeletedPonds: [],
      selectedHeaders: {},
      pondSearch: "",
      formName: "deletePondConfirmation",
      tableProps: {
        // border: true,
        size: "small",
        // height:250,
        defaultSort: {
          prop: "title",
          order: "ascending"
        },
        height: "calc(100vh - var(--table-gutter))"
      },
      disabled: true,
      filters: [
        {
          value: "",
          filterFn: (row, filter) => {
            return row.title.toLowerCase().includes(filter.value.toLowerCase());
          }
        }
      ],
      selectedColumns: [],
      isDeleteConfirmationPopup: false,
      form: {
        deleteConfirmationString: '',
      }
    };
  },
  watch: {
    selectedColumns: function(newValue, oldValue) {
      this.$nextTick(() => {
        if (this.$refs.pondsettings) {
          this.$refs.pondsettings.$refs.dataTables.$refs.elTable.doLayout();
        }
      });
    }
  },
  computed: {
    ...mapGetters("pond", {
      Ponds: "getPonds"
    }),
    ...mapGetters("user", {
      getUserCountryName: "getUserCountryName",
      getUserCountryCode: "getUserCountryCode",
      getUserLocationsObj: "getUserLocationsObj",
      getCurrUserLocation: "getCurrUserLocation",
      isReadOnly: "isReadOnly",
      getPreferredUnits: "getPreferredUnits",
      getUserProfile: "getUserProfile",
      getUserTimeZoneString: "getUserTimeZoneString"
    }),

    ...mapGetters("schedules", {
      getMapPondIdFT: "getMapPondIdFT"
    }),
    getDialogTitle() {
      return this.$t('Comn_delete_pond');
    },
    shouldDeleteConfirmationButtonEnable() {
      return !(this.form.deleteConfirmationString.trim() === 'delete pond' || this.form.deleteConfirmationString.trim() === 'eliminar piscina')
    },
    DeleteConfirmationFormLabel() {
      return [
        {
          label: this.$t("pond_delete_confirmation_label"),
          placeholder: this.$t("Usrs_enter_first_name")
        },
      ];
    },
    getPondSizeAndUnits() {
      return (size, units) => {
        // const toalSizeAndUnits = {
        //   value: size || 0,
        //   previousunits: units,
        //   units: this.getPreferredUnits.pond_area_units,
        //   displayFormatter: () => {
        //     return ``;
        //   },
        //   modifiersParams: { digitPrecision: [1] }
        // };
        return +this.$commonUtils.castPondAreaSize(
        +size || 0,
        units,
       this.getPreferredUnits.pond_area_units
      ).toFixed(2); // .slice(0, -1)
      };
    },
    getPLStockedAndUnits() {
      return (pLsStockedValue) => {
        const toalPLStockedAndUnits = {
          value: pLsStockedValue || 0,
          previousunits: "",
          units: "",
          modifiersParams: {
            formatNumber: [this.getUserCountryCode],
            digitPrecision: [0]
          }
        };
        return toalPLStockedAndUnits;
      };
    },
    getUserId() {
      return this.getUserProfile._id;
    },
    getLocaleObj: function() {
      return { locale: this.$i18n.locale };
    },
    tableColumns() {
      return {
        title: {
          prop: "title",
          label: this.$t("Comn_pond_name"),
          sortable: "custom",
          default: true,
          enableSearch: true,
          searchProperty: "title",
          downloadOption: true
        },
        size: {
          prop: "size",
          label: this.$tc("Pond_size", 1),
          sub_label: `(${this.getPreferredUnits.pond_area_units})`,
          sortable: "custom",
          default: true,
          downloadOption: true
        },
        ui_cultivation_date: {
          prop: "ui_cultivation_date",
          label: this.$t("Comn_stocking_date"),
          sortable: "custom",
          default: true,
          downloadOption: true
        },
        post_larva_stocked: {
          prop: "post_larva_stocked",
          label: this.$t("Pond_pls_stocked"),
          sortable: "custom",
          default: true,
          downloadOption: true
        },
        feed_template_title: {
          prop: "feed_template_title",
          label: this.$t("PM_pond_feeding_set"),
          sortable: "custom",
          default: true,
          downloadOption: true,
          enableSearch: true,
          searchProperty: "feed_template_title"
        },
        status: {
          prop: "status",
          label: `${this.$t("Comn_actions")}`,
          minWidth: 100,
          default: true,
          downloadOption: false
        },
        test_status: {
          prop: "test_status",
          label: `${this.$t("Test_status")}`,
          // minWidth: 35,
          default: false,
          downloadOption: false
        }
      };
    },
    downloadColumns() {
      return [
        {
          header: this.tableColumns.title.label,
          dataKey: "title",
          emptyValue: "--",
          name: "title",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 15
        },
        {
          header:
            this.tableColumns.size.label + this.tableColumns.size.sub_label,
          dataKey: (value) => {
            return this.ftm__digitPrecision(
              this.upm__applyConversion(
                value.size,
                value.units,
                this.getPreferredUnits.pond_area_units
              ),
              2
            );
          },
          name: "size",
          emptyValue: "--",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 15
        },
        {
          header: this.tableColumns.ui_cultivation_date.label,
          dataKey: (value) => {
            return value.status === "ACTIVE"
              ? this.ftm__formatEmpty(
                  this.dhm__castUserUTCToUTCISO(value.ui_cultivation_date)
                )
              : "--";
          },
          emptyValue: "--",
          name: "ui_cultivation_date",
          skipColumn: false,
          formatters: [
            {
              method: "cultivation_date_format",
              params: [this.upm__getFormatDateString]
            }
          ],
          xcelcolumnWidth: 15
        },
        {
          header: this.tableColumns.post_larva_stocked.label,
          dataKey: (value) => {
            return this.ftm__formatEmpty(
              this.ftm__digitPrecision(value.post_larva_stocked),
              true,
              "--"
            );
          },
          name: "post_larva_stocked",
          emptyValue: "--",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 15
        },
        {
          header: this.tableColumns.feed_template_title.label,
          dataKey: (value) => {
            return this.ftm__formatEmpty(value.feed_template_title, true, "--");
          },
          name: "feed_template_title",
          emptyValue: "--",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 20
        }
      ];
    },
    dataValues() {
      return this.statusWiseFilter();
    },
    isDeleteEnable() {
      return (pond) => {
        return (
          pond.pond_mothers.length > 0 ||
          pond.pond_guards.length > 0 ||
          pond.shrimp_talks.length > 0
        );
      };
    }
  },
  async mounted() {
    this.loading = true;
    await this.loadPondsList();
  },
  methods: {
    ...mapActions("pond", {
      fetchAllPonds: "fetchAllPonds",
      updatePondDetails: "updatePondDetails",
      enablePond: "enablePond",
      updateTestPonds: "updateTestPonds"
    }),
    ...mapActions("schedules", {
      fetchAllFeedTemplates: "fetchAllFeedTemplates"
    }),
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    handelSearchChange() {
      if (this.pondSearch !== '') {
        this.mixPanelEventGenerator({ eventName: "Settings - Ponds - Search" });
      }
    },
    handelSaveColumns() {
      this.mixPanelEventGenerator({ eventName: "Settings - Ponds - Column Selector - Save" });
    },
    resetForm() {
      this.form.deleteConfirmationString = '';
      this.$refs[this.formName].resetFields();
    },
    handleDialogClose(message) {
      this.isDeleteConfirmationPopup = false;
      this.$emit("close", message);
    },
    handleTableDataDownload(downloadtype) {
      if (downloadtype === "Comn_download_as_pdf") {
        this.pdfdownload();
      } else {
        this.xceldownload();
      }
      this.mixPanelEventGenerator({ eventName: "Settings - Ponds - Download" });
    },

    xceldownload() {
      this.exp__Download(
        this.$refs.pondsettings.$refs.dataTables.sortedData,
        this.downloadColumns,
        "excel"
      );
    },
    pdfdownload() {
      this.exp__Download(
        this.$refs.pondsettings.$refs.dataTables.sortedData,
        this.downloadColumns,
        "pdf"
      );
    },

    formatCultivationDate(date) {
      if (date === undefined) {
        return "00-000 00";
      }
      return this.dhm__formatByTZOnUTCISOWithLocale(
        this.dhm__castUserUTCToUTCISO(date),
        this.upm__getFormatDateString
      );
    },
    statusWiseFilter() {
      if (this.selectedStatus === "ALL") {
        return this.data.sort((pm1, pm2) => {
          return this.$commonUtils.alphaNumericComparator(pm1.title, pm2.title);
        });
      } else {
        return this.data
          .filter((x) => x.status === this.selectedStatus)
          .sort((pm1, pm2) => {
            return this.$commonUtils.alphaNumericComparator(
              pm1.title,
              pm2.title
            );
          });
      }
    },
    sortMethods() {
      return {
        title: this.sortPondWise,
        ui_cultivation_date: this.sortByCultivationDate,
        feed_template_title: this.sortTemplateWise
      };
    },
    sortTemplateWise(a, b) {
      return this.$commonUtils.alphaNumericComparator(a, b);
    },
    sortPondWise(a, b) {
      return this.$commonUtils.alphaNumericComparator(a, b);
    },
    sortByCultivationDate(a, b) {
      return this.dhm__dateUtilsLib.dateComparator(a, b, true);
    },
    closeDialog(event) {
      this.dialogVisible = false;
      if (event !== "close") {
        this.loadPondsList();
        this.mixPanelEventGenerator({ eventName: "Settings - Ponds - " + this.actionToPerform + " Pond - Save" });
      }
    },
    doesPondHasDevices: function(row, index) {
      if (row.pond_mothers.length > 0) {
        return true;
      }
      return false;
    },
    loadPondsList: async function() {
      this.loading = true;
      try {
        const response1 = await this.fetchAllPonds({
          location_id: this.getCurrUserLocation._id,
          get_all: true
        });
        const ftquery = {
          location_id: this.getCurrUserLocation._id
        };
        await this.fetchAllFeedTemplates(ftquery);
        this.data = this.$lodash
          .cloneDeep(this.Ponds)
          .map((x) => {
            if (x.status === "INACTIVE") {
              x.ui_cultivation_date = undefined;
            } else {
              x.ui_cultivation_date = x.cultivation_date;
            }
            x.feed_template_title = (
              this.getMapPondIdFT[x._id] || { title: undefined }
            ).title;
            return x;
          })
          .sort((a, b) =>
            this.$commonUtils.alphaNumericComparator(a.title, b.title)
          );
        this.total = response1.totalPonds;
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.$nextTick(() => {
          if (this.$refs.pondsettings) {
            this.$refs.pondsettings.$refs.dataTables.$refs.elTable.doLayout();
          }
        });
        this.loading = false;
      }
    },
    showPondDetails(row, column, cell, event) {
      if (column.property === this.tableColumns.title.prop) {
        this.rdm__navigateToPathWithLocationId({
          path: "pond-details",
          query: {
            pondId: row.pond_id
          }
        });
      }
    },
    handleSelectionChange(arrSelectedPonds) {
      this.multiSeletedPonds = arrSelectedPonds;
    },
    async handleChangeStatus(event, action, pond) {
      try {
        if (action === 'RESET') {
          this.$gblUAMCanUserDelete(this.tabData);
        } else {
          this.$gblUAMCanUserEdit(this.tabData);
        }
        let actionStr = this.$t("remove");
        let actingOn = this.$t("Comn_from");
        if (event) {
          actionStr = this.$t("move");
          actingOn = this.$t("Comn_to");
        }
        if (action === "TESTING") {
          await this.nofm__primaryConfirmation(
            pond.title,
            actionStr,
            actingOn,
            this.$tc("Comn_test", 1)
          );
          const payload = {
            pond_id: pond.pond_id,
            is_test: event
          };
          await this.updateTestPonds(payload);
          this.mixPanelEventGenerator({ eventName: "Settings - Ponds - " + action + " - Ponds" });
          this.$notify({
            title: this.$t("Usrs_success_msg"),
            message: this.$t("Pond_data_upd_success"),
            duration: 5000,
            type: "success"
          });

          await this.loadPondsList();
        } else {
          // if (action === "RESET") {
          //   this.$gblUAMCanUserEdit(this.tabData);

          //   this.$notify({
          //     title: this.$t("failed"),
          //     message: this.$t("no_devices_pond_clear"),
          //     type: "error"
          //   });
          //   return;
          // }
          if (["INACTIVE", "RESET", "ENABLE"].includes(action)) {
            let { pond_id, status, cultivation_date, last_harvest_date } = pond;
            status = action;
            try {
              if (action === "ENABLE") {
                if (!cultivation_date && !last_harvest_date) {
                  status = "INACTIVE";
                } else if (cultivation_date || last_harvest_date) {
                  const stockingDate =
                    cultivation_date &&
                    this.dhm__castUserUTCToUTCDateObj(cultivation_date);
                  const lastHarvestDate =
                    last_harvest_date &&
                    this.dhm__castUserUTCToUTCDateObj(last_harvest_date);
                  if (
                    (this.dhm__dateUtilsLib.isEqual(
                      stockingDate,
                      this.dhm__getTodayInUserTZ
                    ) ||
                      this.dhm__dateUtilsLib.isBefore(
                        stockingDate,
                        this.dhm__getTodayInUserTZ
                      )) &&
                    (!last_harvest_date ||
                      !this.dhm__dateUtilsLib.isAfter(
                        lastHarvestDate,
                        stockingDate
                      ) ||
                      this.dhm__dateUtilsLib.isBefore(
                        lastHarvestDate,
                        stockingDate
                      ))
                  ) {
                    status = "ACTIVE";
                  } else {
                    status = "INACTIVE";
                  }
                }
                this.mixPanelEventGenerator({ eventName: "Settings - Ponds - " + action + " Pond" });
                await this.enablePondnotificationHandler({ pond_id, status });
              } else {
                this.isDeleteConfirmationPopup = true;
                this.deletePayload = {
                  pond_id, status
                };
                // await this.nofm__deleteConfirmation(pond.title, "table");
                // this.loading = true;
                // await this.notificationHandler({ pond_id, status });
                // await this.notificationHandler({ pond_id, status });
              }
            } catch (err) {
              this.ehm__errorMessages(err, true);
            }
          } else {
            this.selectedPond = this.$lodash.cloneDeep(pond);
            this.dialogVisible = true;
            this.actionToPerform = action;
          }
        }
      } catch (err) {
        this.ehm__errorMessages(err, true);
      }
    },
    handleStatusChange(status) {
      this.selectedStatus = status;
      this.mixPanelEventGenerator({ eventName: "Settings - Ponds - " + status + " - Ponds" });
    },
    async submitForm() {
      const { pond_id, status } = this.deletePayload;
      if (this.loading) {
        return;
      }
      this.loading = true;
      try {
       this.isDeleteConfirmationPopup = false;
       await this.notificationHandler({ pond_id, status });
        this.mixPanelEventGenerator({ eventName: "Settings - Ponds - DELETE Pond" });
      } catch (err) {
        this.ehm__errorMessages(err, false);
      } finally {
        this.loading = false;
      }
    },

    handleChangeInColumnSelection(selectedColumns) {
      this.selectedColumns = selectedColumns;
    },
    async notificationHandler(reqPayload) {
      try {
        await this.updatePondDetails(reqPayload);
        if (reqPayload.status === "RESET") {
          this.$notify({
            title: this.$t("Usrs_success_msg"),
            message: this.$t("pond-deleted-successfully"),
            duration: 5000,
            type: "success"
          });
        } else {
          this.$notify({
            title: this.$t("Usrs_success_msg"),
            message: this.$t("pond-details-updated-successfully"),
            duration: 5000,
            type: "success"
          });
        }
        this.loadPondsList();
      } catch (err) {
        // this.ehm__errorMessages(err, true);
        this.$notify({
          title: this.$t("failed"),
          message: this.$t("unable-to-update-the-pond-details"),
          duration: 5000,
          type: "error"
        });
      }
    },
    async enablePondnotificationHandler(reqPayload) {
      try {
        await this.enablePond(reqPayload);
        this.$notify({
          title: this.$t("Usrs_success_msg"),
          message: this.$t("pond-enabled-successfully"),
          duration: 5000,
          type: "success"
        });
        this.loadPondsList();
      } catch (err) {
        // this.ehm__errorMessages(err, true);
        this.$notify({
          title: this.$t("failed"),
          message: this.$t("unable-to-enable-the-pond"),
          duration: 5000,
          type: "error"
        });
      }
    },
    handleAddPondClick() {
      try {
        this.$gblUAMCanUserEdit(this.tabData);
        this.rdm__navigateToPathWithLocationId({ path: "map-view" });
      } catch (error) {
        this.ehm__errorMessages(error, true);
      }
    }
  }
};
</script>

<style lang="scss">
.pond-table {
  @include responsiveProperty(--table-gutter, 216px, 230px, 230px);
  .disabled {
    pointer-events: none;
    opacity: 0.4;
  }

  #pond-settings__table {
    padding: 0 12px;
  }
  .status-container {
    display: inline-flex;
    .table-row-btn-container {
      display: flex;
      align-items: center;
    }
  }
  .action-toolbar {
    padding: 12px 10px;
  }
  .el-pagination__sizes {
    .el-input--mini .el-input__inner {
      height: 22px;
      line-height: 22px;
    }
  }
  .el-pagination__total {
    margin-left: 20px;
  }
  .pagination-bar {
    margin-bottom: 10px;
  }

  .action-btn {
    .img {
      display: inline-flex;
    }
    .material-icons-round {
      font-size: 12px;
    }
  }

  .edit-btn-container {
    // width: 56.61px;
    // @include responsiveProperty(width, 56.62px, 66px, 80.7px);
    display: inline-flex;
  }
  .el-radio-group {
    .el-radio-button__inner {
      padding: 7px 15px;
    }
  }
  .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    color: #ffffff;
    background: $button-color;
    border-color: $button-color;
    box-shadow: -1px 0 0 0 $button-color;
  }
  .el-radio-button__orig-radio + .el-radio-button__inner {
    color: #0a2463fe;
  }
  .el-radio-button__orig-radio:disabled + .el-radio-button__inner {
    opacity: 0.6;
  }
  .delete-pond-confirmation-dialog {
    .el-dialog {
      border-radius: 10px !important;
      .el-dialog__body {
        .el-form-item {
          display: flex;
          flex-direction: column;
          .el-form-item__label {
            float: left !important;
            text-align: left !important;
            word-break: break-word;
          }
        }
      }
    }
  }
}
</style>
